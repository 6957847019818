<template>
  <div>
    <d-body-top-bar :title="$t('data.menu.payment.title')">
      <div slot="tree">
        <label class="bg-doinsport-primary ml-1"> {{ $t('general.actions.list') }}</label>
      </div>
    </d-body-top-bar>
    <div class="container-fluid">
     <!-- <custom-infos/> Todo when api data is ready -->
      <full-infos/>
    </div>
  </div>
</template>
<script>
  export default {
    components: {
      CustomInfos: () => import('./content/custom-infos/Index'),
      FullInfos: () => import('./content/full-infos/Index'),
    },
    props: {
      client: {
        type: Object,
        default: () => {}
      },
    }
  }
</script>
